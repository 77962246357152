(function ($) {
	$(window).load(function () {
		// owl-carousel
		$(".owl-carousel:not(.slider)").owlCarousel({
			items: 1,
			loop: true,

			// autoplay
			autoplay: true,
			autoplayTimeout: 7000,

			// speed
			smartSpeed: 500,

			// animation
			animateIn: "fadeIn",
			animateOut: "fadeOut",

			// navigation
			nav: true,
			navText: [
				'<i class="fas fa-chevron-left"></i>',
				'<i class="fas fa-chevron-right"></i>',
			],
			dots: true,

			// drag
			mouseDrag: false,
		});

		// slider-with-arrow
		$(".slider.slider-with-arrow").owlCarousel({
			items: 1,
			margin: 30,
			loop: true,

			// autoplay
			autoplay: true,
			autoplayTimeout: 7000,

			// speed
			smartSpeed: 500,

			// navigation
			nav: true,
			navText: [
				'<i class="fas fa-chevron-left"></i>',
				'<i class="fas fa-chevron-right"></i>',
			],
			dots: false,

			// drag
			mouseDrag: true,

			// responsive
			responsive: {
				0: {
					items: 1,
				},
				576: {
					items: 2,
				},
				768: {
					items: 2,
				},
				992: {
					items: 3,
				},
				1200: {
					items: 4,
				},
			},
		});

		// slider
		$(".slider").owlCarousel({
			items: 1,
			margin: 40,
			loop: true,

			// autoplay
			autoplay: true,
			autoplayTimeout: 7000,

			// speed
			smartSpeed: 500,

			// navigation
			nav: false,
			navText: [
				'<i class="fas fa-chevron-left"></i>',
				'<i class="fas fa-chevron-right"></i>',
			],
			dots: true,

			// drag
			mouseDrag: true,

			// responsive
			responsive: {
				0: {
					items: 1,
				},
				576: {
					items: 2,
				},
				768: {
					items: 2,
				},
				992: {
					items: 3,
				},
				1200: {
					items: 3,
				},
			},
		});

		// form-sent
		setTimeout(() => {
			var locationSearch = "" + document.location.search;
			if (
				(-1 === locationSearch.indexOf("form[sent]") &&
					-1 === decodeURIComponent(locationSearch).indexOf("form[sent]")) ||
				-1 === document.location.hash.indexOf("#form")
			) {
				return;
			}

			var $headerHeight = $(".header");
			var $message = $(".form-success-message");

			if (!$headerHeight.length || !$message.length) {
				return;
			}

			var currentScrollTop = $("html").scrollTop();
			var newScrollTop =
				$message.offset().top - $headerHeight.outerHeight() - 30;
			if (newScrollTop >= currentScrollTop) {
				return;
			}

			$("html, body").animate({
				scrollTop: $message.offset().top - $headerHeight.outerHeight() - 30,
			});
		}, 500);
	});

	$(document).ready(function () {
		// sticky
		require("../../vendor/w3media/framework/assets/js/V2/body-sticky-class");

		// make kasabon in smooth layout sticky
		require("../../vendor/w3media/framework/assets/js/V2/hc-sticky");
		$(".w3media-booking-receipt-widget").hcSticky({
			stickTo: ".content-section",
			top: 100,
			responsive: {
				992: {
					disable: true,
				},
			},
		});

		// Search-Box click
		$(".search-icon").click(function () {
			const searchInput = $(this).parent().find(".form-control");
			searchInput.toggleClass("show")
			if ( searchInput.hasClass("show") ) {
				searchInput.focus();
			}
		});

		// fancybox
		$("a[data-fancybox]").fancybox({
			buttons: [
				//"zoom",
				//"share",
				//"slideShow",
				//"fullScreen",
				//"download",
				"thumbs",
				"close",
			],
		});

		// scroll-to-content
		$(".js-3w-scroll-to-content").on("click", function (event) {
			event.preventDefault();
			var identifier = $(this).attr("data-target");
			var $element = $(identifier);

			if ($element) {
				$("html, body").animate({ scrollTop: $element.offset().top }, 0);
			}
		});

		// navbar-toggler
		$(document).on('click', '.navbar-toggler', function () {
			$('body').toggleClass('navbar-collapse-active');
		});

		// menu toggle on sub menu close
		$('.menu .nav-item').on('hide.bs.dropdown', function () {
			if (window.innerWidth < 992) return;

			setTimeout(() => {
				if (!$(this).siblings('.dropdown').hasClass('show')) {
					$(".header").removeClass('header-active');
					$('.header').css('padding-bottom', '');
				}
			}, 10);
		});

		// menu toggle
		$('.menu .nav-item .nav-link').on('click', function (e) {

			if (window.innerWidth < 992) return;

			const $this = $(this);

			if (!$this.parent('.nav-item').hasClass('show')) {

				$(".header").addClass('header-active');

				// get dropdown menu height
				setTimeout(() => {
					const menuHeight = $this.next('.dropdown-menu').outerHeight();
					const cssTopProp = parseInt($this.next('.dropdown-menu').css('top'), 10) / 2;

					$('.header').css('padding-bottom', menuHeight + cssTopProp + 'px');
				}, 10);

			} else {
				$(".header").removeClass('header-active');
				$('.header').css('padding-bottom', '');
			}
		});

		// faq fix
		let faqlinks = document.querySelectorAll(".faq-category a");
		faqlinks.forEach((link) => {
			if (link.id) {
				link.id = ""
				link.href = "#";
			}
		});

		// prevent scrolling on #TommyBookingSupport page
		$(window).on("hashchange", function (e) {
			if ($("#TommyBookingSupport").length > 0) {
				e.preventDefault();

				// disable scrolling
				$('body').css('overflow', 'hidden');

				// set current scroll position
				$("html, body").animate({
					scrollTop: $("#TommyBookingSupport").offset().top - 120,
				});

				// enable scrolling
				setTimeout(() => {
					$('body').css('overflow', 'auto');
				}, 10)
			}
		});

		// Slide Wide Image

		// Image movement speed
		const moveSpeed = 25;

		let moveInterval = null;

		$('.image-slider .slider-controls .slide-right').on('mouseenter touchstart', function(e) {
			e.preventDefault();

			const thisBtn = $(this);
			const imgElement = $('.image-slider .slider-img');
			const containerElement = $('.image-slider .slider-container');
			const imgWidth = imgElement.width();
			const windowWidth = window.innerWidth;
			const maxImgPosX = imgWidth - windowWidth;
			let imgPosX = parseInt(containerElement.css('left'));

			moveInterval = setInterval(() => {
				if (imgPosX < maxImgPosX) {

					thisBtn.prev('.slide-left').removeAttr('disabled');

					if (imgPosX < 0) {
						imgPosX = imgPosX * -1;
					}

					imgPosX += moveSpeed;

					if (imgPosX > maxImgPosX) {
						imgPosX = imgPosX + (maxImgPosX - imgPosX);
						thisBtn.attr('disabled', 'disabled');
					}

					containerElement.css('left', `-${imgPosX}px`);
				} else {
					clearInterval(moveInterval);
				}
			}, 100);

		}).on('mouseleave touchend', function() {
			clearInterval(moveInterval); // Stop moving the image on mouse leave or touch end
		});

		$('.image-slider .slider-controls .slide-left').on('mouseenter touchstart', function(e) {
			e.preventDefault();

			const thisBtn = $(this);
			const imgElement = $('.image-slider .slider-img');
			const containerElement = $('.image-slider .slider-container');
			const imgWidth = imgElement.width();
			const windowWidth = window.innerWidth;
			const maxImgPosX = imgWidth - windowWidth;
			let imgPosX = parseInt(containerElement.css('left'));

			moveInterval = setInterval(() => {
				if (imgPosX < maxImgPosX) {

					thisBtn.next('.slide-right').removeAttr('disabled');

					if (imgPosX < 0) {
						imgPosX = imgPosX * -1;
					}

					imgPosX -= moveSpeed;

					if (imgPosX < 0) {
						imgPosX = 0;
						thisBtn.attr('disabled', 'disabled');
					}

					containerElement.css('left', `-${imgPosX}px`);
				} else {
					clearInterval(moveInterval);
				}
			}, 100);

		}).on('mouseleave touchend', function() {
			clearInterval(moveInterval); // Stop moving the image on mouse leave or touch end
		});

		// footer-list toggle
		$('.footer-list h5').on('click',function(e) {
			if ( $(window).width() > 768 ) return;

			$(this).toggleClass('active').next('.footer-list').slideToggle();
		});



		leadSectionPadding();
		$(window).on('resize', leadSectionPadding);

		// Load HubSpot forms script
		// ToverTuin
		if ( $('body').hasClass('w3-channel-tovertuin') ) {
			$.getScript("https://js-eu1.hsforms.net/forms/embed/v2.js", function () {
				hbspt.forms.create({
					portalId: "26011934",
					formId: "530be451-242a-418b-a2f8-0ef9e2b9c0cc",
					region: "eu1",
					target: "#hubspot-form"
				});
			});
		}
		// Resort
		if ( $('body').hasClass('w3-channel-resort') ) {
			$.getScript("https://js-eu1.hsforms.net/forms/embed/v2.js", function () {
				hbspt.forms.create({
					portalId: "26011934",
					formId: "b2468038-127f-4e6f-b343-0703918abe1d",
					region: "eu1",
					target: "#hubspot-form"
				});
			});
		}
		// AvonturenPark
		if ( $('body').hasClass('w3-channel-avonturenpark') ) {
			$.getScript("https://js-eu1.hsforms.net/forms/embed/v2.js", function () {
				hbspt.forms.create({
					portalId: "26011934",
					formId: "838b90cd-4f28-4296-9e8e-f9c7fcbab5a9",
					region: "eu1",
					target: "#hubspot-form"
				});
			});
		}

	});

	// Get multiple elements instead of a single one using "querySelectorAll"
	// const elements = document.querySelectorAll(
	// 	".mini-sab .desktop-img, .mini-sab .mobile-img, .title-section .img-fluid, .page-block.wysiwyg, .page-block.link, .page-block.title, .page-block.subtitle, .page-block.resource-image, .page-block.footer-list, .page-block.footer-logolink, .page-block.footer-text, .page-block.collection.grid .item, .page-block.collection.slider, .gallery-item, .global-usps li, .form"
	// );

	// Loop over the elements and add each one to the observer
	// initObserver(elements);
})(jQuery);

// Create the observer like the examples above
// function createIntersectionObserver() {
// 	return new IntersectionObserver((entries) => {
// 		entries.forEach((entry) => {
// 			if (entry.isIntersecting) {
// 				entry.target.classList.add("animate__fadeInUp");
// 			}
// 		});
// 	},
// 		{
// 			threshold: 0.1,
// 		}
// 	);
// }

// function initObserver(elements) {
// 	window.observerInstance = createIntersectionObserver();
// 	elements.forEach((element) => window.observerInstance.observe(element));
// }

function leadSectionPadding() {

	// lead-section padding
	const leadSection = $('.lead-section');
	setTimeout(() => {
		const miniSabHeight = $('.mini-sab .container-holder').outerHeight();

		const miniSabOffsetTop = ($(window).width() >= 992) ? 140 : 70;
		const whiteSpace = 50;

		if ( leadSection.length > 0 ) {
			leadSection.css('padding-top', miniSabHeight - miniSabOffsetTop + whiteSpace);
		} else {
			$('main > section:first-child').css('padding-top', miniSabHeight - miniSabOffsetTop + whiteSpace);
		}

	}, 100);
}
